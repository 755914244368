<template>
<div class="product">
    <topnav active="product"></topnav>
    <topmenu active="product" @get="get_menu"></topmenu>
    
    
    <div class="product_wrap public_width">
        <topbread active="product" :first="first" :first_id="first_id" :second="second" :second_id="second_id" v-if="bread_flag" @get="get_menu"></topbread>

        <div class="product_filter d-between" v-if="filter_list && filter_list.length > 0">
            <div class="filter_more d-center" :class="show_filter?'show':''" @click="change_more()">
                <i class="t-icon t-icon-a-zu27"></i>
            </div>
            <div class="filter_wrap">
                <div class="filter_items">
                    <div class="filter_list ">
                        <div class="filter_item" v-for="(item, index) in filter_list" :key="item">
                            <h5 class="filter_title">{{item.filter_name}}</h5>
                            <div class="filter_box">
                                <div class="filter_input d-flex">
                                    <el-input class="filter_ipt" v-model="item.ipt" @input="change_filter($event,item,index)"></el-input>
                                    <div class="btn d-center"><i class="t-icon t-icon-a-zu1051"></i></div>
                                </div>
                                <ul class="show_list" :class="{'show': show_filter}" v-if="item.value_ary.length>0">    
                                    <li :class="[{'grey':!v.show}]"  v-for="(v,k) in item.value_list" :key="k" @click="add_cond(v, k, item, index)">{{ v.value }}</li>
                                </ul>
                            </div>
                        </div>
                    </div> 
                </div>

                <ul class="cond_list">
                    <li class="cond_item" v-for="(v,k) in cond_list" :key="v">
                        <span>{{v}}</span>
                        <p class="icon" @click="clear_cond(v,k)"><i class="iconfont icon-xx"></i></p>
                    </li>
                </ul>

                <div class="result d-flex">
                    <div class="result_remove" @click="remove_filter()">清除筛选</div>   
                    <div class="result_use" @click="use_filter()">应用筛选</div>
                    <div class="result_num">筛选结果：<span>{{result || 0}}</span></div> 
                </div>
            </div>

        </div>

        <div v-if="product_show">
            <div class="product_total d-between">
                <h3 class="product_title">全部</h3>
                <div class="product_num">当前共<span>{{pre_total || 0}}</span>件商品</div>
            </div>

            <div class="productMain" >
                <div class="product_table">
                    <div class="productMain_hd">
                        <ul class="order_lists d-flex productTop">
                            <li class="list_con">图片</li>
                            <li class="list_desc">产品</li>
                            <li class="list_desc">参数描述</li>
                            <li class="list_price">价格（含13%税）</li>
                            <li class="list_store">库存</li>
                            <li class="list_amount">操作</li>
                        </ul>
                    </div>
                    <div class="productBox">
                        <div class="order_content">
                            <ul
                                class="order_lists"
                                v-for="(item, index) in product_list"
                                :key="index"
                            >
                                <!-- 图片 -->
                                <li class="list_con">
                                  <div class="list_img">
                                      <img class="img" :src="item.thumbs&& item.thumbs[0] ? item.thumbs[0]:''" alt=""  @mouseover.stop="open($event,item)" />
                                        <div class="list_box" v-if="item.show_big">
                                          <div class="list_view" >
                                            <img class="big" :src="item.img_big" />
                                            <img class="arrow" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/19/%E9%A3%9E%E4%B9%A620220119-151903.png">
                                            <img class="sample" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211216-135814.png" v-if="item.purchase_limit && item.purchase_limit > 0">
                                            <div class="detail_banner d-between">
                                                <!--<p class="action d-center" @click="prev()"><i class="prev iconfont icon-xz"></i></p>-->
                                                <el-carousel ref="sale" class="show-img" trigger="click" :initial-index="0" height="90px" :arrow="'always'" >
                                                    <div class="show-item">
                                                        <el-carousel-item class="show-carousel d-between"  v-for="(v,k) in item.banner_list" :key="k+'baner'">
                                                            <div class="carousel_box d-flex" v-for="(a,b) in v" :key="b" @click="change_img(item,a)">
                                                                <img class="banner_img" :class="item.img_big == a ? 'active' : ''" :src="a" />
                                                            </div>
                                                        </el-carousel-item>
                                                    </div>
                                                </el-carousel>
                                                <!--<p class="action d-center" @click="next()"><i class="next iconfont icon-xy" ></i></p>-->
                                            </div>
                                            <div class="font">温馨提醒：图片仅供参考，商品以实物为准</div> 
                                          </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="list_info">
                                    <!-- 产品名称 -->                             
                                    <div class="list_name" @click="to_detail(item)"><span class="name">{{ item.name }}</span><span class="type" v-if="item.second_category && item.second_category.name"> {{item.second_category && item.second_category.name  ? item.second_category.name : ''}}</span></div>
                                    
                                    <!-- 规格型号 -->
                                    <div class="list_item"><span class="title">规格型号</span><span class="val">{{item.standard}}</span></div>
                                    <!--封装 -->
                                    <div class="list_item"><span class="title">封装</span><span class="val">{{item.package}}</span></div>
                                    <!--品牌 -->
                                    <div class="list_item" v-if="item.brand"><span class="title">品牌</span><span class="val">{{item.brand}}</span></div>
                                    <!--数据手册 -->
                                    <div class="list_book" v-if="item.databook"><i class="t-icon t-icon-E-pdf"></i><a :href="item.databook" target="_blank">数据手册</a></div>
                                </li>

                                <!-- 参数描述 -->
                                <li class="list_desc">
                                  <el-popover
                                    popper-class="pb_pop"
                                    placement="bottom-start"
                                    :width="200"
                                    trigger="hover">
                                        <template v-slot:reference> 
                                            <div class="text-seven">
                                                <div v-if="item.filters && item.filters.length > 0">
                                                    <p v-for="(v) in item.filters" :key="(v)">{{v.filter_name}} : {{v.value}}</p>
                                                </div>
                                                <p v-else>{{ item.desc }}</p>
                                            </div>	
                                        </template>
                                        <div v-if="item.filters && item.filters.length > 0">
                                            <p v-for="(v) in item.filters" :key="(v)">{{v.filter_name}} : {{v.value}}</p>
                                        </div>
                                        <p v-else>{{ item.desc }}</p>
                                    </el-popover>        
                                </li>

                                <!-- 单价 -->
                                <li class="list_price">
                                    <div class="price_box">
                                        <p v-for="(v, k) in item.price_ladder" :key="v">{{k}}+/￥{{v}}</p>
                                    </div>
                                </li>
                                
                                <!-- 库存 -->
                                <li class="list_store">
                                  <p class="store_num">{{ item.stock }}个</p>
                                  <p class="store_pack" v-if="item.pack> 0 && item.pack_type">{{ item.pack }}个 / {{item.pack_type}} </p>
                                </li>

                                <!-- 购买数量 -->
                                <li class="list_amount">
                                  <div class="d-flex">
                                    <div class="amount_wrap">
                                        <div class="amount_box d-flex">
                                        <el-button
                                            class="reduce"
                                            @click.stop="sub(item)"
                                            :disabled="item.stock <= 0 || item.stock < item.min_num"
                                            >-</el-button
                                        >

                                        <el-input
                                            type="number"
                                            class="sum"
                                            v-model="item.num"
                                            @blur.stop="num_blur($event, item)"
                                            :disabled="item.stock <= 0 || item.stock < item.min_num"
                                        />

                                        <el-button
                                            class="plus"
                                            @click.stop="add(item)"
                                            :disabled="item.stock <= 0 || item.stock < item.min_num"
                                            >+</el-button
                                        >
                                        </div>

                                        <div class="inventory_count">总额：<p class="sum_price">￥{{ item.total }}</p></div>
                                        
                                    </div>
                                    <div>
                                      <el-select v-model="item.type" placeholder="" size="small" @change="change_unit($event,item)" :disabled="item.stock <= 0 || item.stock < item.min_num">
                                        <el-option
                                          v-for="item in item.purchase_request_str"
                                          :key="item"
                                          :label="item"
                                          :value="item"
                                        >
                                        </el-option>
                                      </el-select>
                                    </div>

                                  </div>
                                  <div class="op_wrap d-flex">
                                      <el-button class="join d-flex" @click.stop="join(item)" :disabled="item.stock <= 0 || item.stock < item.min_num">
                                          <div class="d-center">
                                              <i class="iconfont icon-a-zu11191"></i><span>加入购物车</span>
                                          </div>
                                      </el-button>

                                      <div class="blue_count" v-if="item.purchase_limit>0">单人限购<p class="blue_price">{{item.purchase_limit}}</p></div>
                                      <!--<div class="inventory_count" v-if="item.min_num>0">起订量<p class="sum_price">{{item.min_num}}</p></div>-->
                                  </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="product_page d-center">
                    <el-pagination
                        v-if="total > page_size"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        layout="total, prev, pager, next, jumper"
                        :total="total"
                        :page-size="page_size"
                        background>
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="bgc_empty d-center" v-else>
            <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png" alt="" />
            <div class="bgc_txt">
                <p class="none">暂未找到相关商品</p>
                <p class="can">您可以：</p>
                <p class="num">1、修改搜索词进行重新搜索</p>
                <p class="num">2、或联系平台客服，进行线下定制服务</p>
            </div>
        </div>
    </div>

    <foot />
    <toolbar />
</div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from '@/components/Menu.vue';
import topbread from '@/components/Bread.vue'
import toolbar from "@/components/Toolbar.vue";
import foot from "@/components/Footer.vue";
import { postData } from "@/api/user";
import { toRefs, reactive, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute,useRouter } from "vue-router";
import { ElMessage } from "element-plus";

export default {
    name: "product",
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        store.commit('setCount');

        let state = reactive({
            userid: store.state.userInfo.id,
            product_list: [],
            product_show: true,
            filter_list:[],
            filter_show: true,
            // 筛选项
            cond_list:[],
            cond_result:[],
            // 输入框搜索
            search_list:[],
            first: route.query.first,
            second: route.query.second,
            first_id: route.query.first_id,
            second_id: route.query.second_id,
            bread_flag: true,
            id: 0,
            page: 1,
            total: 0,
            pre_total:0, //应用筛选的总数
            page_size: 0,
            result: 0,
            show_filter: false
        });

        let get_list = (bol) => {
            // bol为真，筛选商品列表
            let url = `api/search/by_category`;
            postData({
                url: url,
                data: {
                    category: state.id,
                    filters: state.cond_result,
                    page: state.page
                },
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    // 输入框条件是否都为空
                    let flag = true
                    if(state.search_list.length > 0) {
                        state.search_list.forEach((v)=>{
                            if(v != '') {
                                flag = false
                            }
                        })
                    }
                    state.filter_list = data.data.filters_with_values;
                    state.filter_show = state.filter_list && state.filter_list.length > 0
                    if(state.filter_list.length > 0) {
                        state.filter_list.forEach((v,k) => {
                            v.value_list = v.value_format
                            v.show = false

                            if(!flag) {
                                state.search_list.forEach((vv,kk) => {
                                    if(k == kk && vv != '') {
                                        v.ipt = vv.toString()
                                        format_list(vv,v)
                                    }
                                })
                            }else {
                                v.ipt = ''
                            }
                        })
                    }

                    let list = data.data.goods
                    state.total = list.total
                    state.page_size = list.per_page
                    state.result = list.total
                    if(bol) {
                        state.product_list = list.data;
                        if(state.product_list.length > 0) {
                            state.product_list.forEach((v) => {
                                v.type='个'
                                // v.num = v.stock > 0 && v.stock > v.min_num ? v.min_num : ''
                                // change_num(v);
                                v.num = v.default_num
                                v.total = v.default_total_price
                                v.show_big = false
                                v.img_big = v.thumbs && v.thumbs[0] ? v.thumbs[0]:''
                                if(v.thumbs.length > 0) {
                                    v.banner_list = []
                                    v.banner_idx = 0
                                    v.thumbs.forEach((a,b) =>{
                                      if(b == 0 ||  b % 2 != 0) {
                                          if(!v.banner_list[v.banner_idx]) {
                                              v.banner_list.push([a])
                                          }else {
                                              v.banner_list[v.banner_idx].push(a)
                                          }
                                      }else {
                                          v.banner_idx++
                                          v.banner_list.push([a])
                                      }
                                    })
                                }
                            })
                        }
                        state.product_show = state.product_list && state.product_list.length > 0;

                        state.pre_total = list.total
                    }
                    
                    
                }
            });
        }

        let init = () => { 
            state.first =route.query.first,
            state.second = route.query.second,
            state.first_id = route.query.first_id,
            state.second_id = route.query.second_id
            state.id = route.query.second_id ?route.query.second_id : route.query.first_id
            get_list(true)

            state.bread_flag = false,
            nextTick(()=> {
                state.bread_flag = true
            })
        }
        init ()
        
        let get_menu = (v) => {
            state.first = v.first,
            state.second = v.second,
            state.first_id = v.first_id,
            state.second_id = v.second_id,
            state.id =v.second_id ? v.second_id : v.first_id
            state.cond_list = []
            state.cond_result = []
            state.search_list = []
            state.search_list = []
            state.cond_list = []
            state.cond_result = []
            get_list(true)

            state.bread_flag = false,
            nextTick(()=> {
                state.bread_flag = true
            })
        }

        // 筛选项
        let change_more = () => {
            state.show_filter = !state.show_filter
        }

        let format_list = (e,v) => {
            let arr = []
            let ary = v.value_list
            
            if(ary.length > 0) {
                ary.forEach((v)=>{
                    if(v.value.indexOf(e) != -1) {
                        arr.push(v)
                    }
                })
            }
            v.value_list = e =='' ? v.value_format : arr
        }

        let change_filter = (e,v,k) => {
            format_list(e,v)
            if(e == '') {
                state.search_list[k] = ''
            }else {
                state.search_list[k] = e
            }
        }

        let add_cond = (v, k, item) => {
            if(state.cond_list.indexOf(v.value)  == -1 && v.show == true) {
                state.cond_list.push(v.value)
                state.cond_result.push([item.filter_id,v.value])
                get_list()
            }   
        }

        let clear_cond = (v,k) => {
            state.cond_list.splice(k,1)
            state.cond_result.splice(k,1)
            get_list()
        }

        let remove_filter = () => {
            state.cond_list = []
            state.cond_result = []
            state.search_list = []
            state.search_list = []
            get_list(true)
        }

        let use_filter = () => {
            state.search_list = []
            get_list(true)
            
        }

        // 商品列表
        // 减 -
        let sub = (v) => {
            if(v.type == '个') {
              if (v.num >= v.min_num *2) {
                  v.num-= v.min_num;
                  change_num(v)
              }
            }else {
              if(v.num > 1) {
                  v.num--;
                  change_num(v)
              }
            }
        };

        // 加 +
        let add = (v) => {
            let limit = v.purchase_limit && v.purchase_limit > 0 ? (v.stock > v.purchase_limit ? v.purchase_limit : v.stock) : v.stock
            let max_pan = Math.floor(limit/v.pack)
            if(v.type == '个') {

              if (v.num <= Number(limit) - v.min_num) {
                  v.num += v.min_num;
                  change_num(v);
              }
            }else {
              if(v.num < max_pan) {
                  v.num++;
                  change_num(v)
              }
            }
        };

        // 输入
        let num_blur = (e, v) => {
            let limit = v.purchase_limit && v.purchase_limit > 0 ? (v.stock > v.purchase_limit ? v.purchase_limit : v.stock) : v.stock
            let max_pan = Math.floor(limit/v.pack)
            let val = e.currentTarget.value.trim();

            if(v.type=="个"){
              if ( val > limit) {
                  v.num = v.min_num*Math.floor(limit/v.min_num) //3*6
                  ElMessage.warning(`最大订购量为${v.num}个，已为您修正。`)
              }else if(val < 1 && v.stock > 0 || val< v.min_num) {
                  v.num = v.min_num;
                  ElMessage.warning(`最小起订量为${v.num}个，已为您修正。`)
              }else if (v.stock <= 0) {
                  v.num = '';
              }else {
                  let ge_max = v.min_num*Math.ceil(val/v.min_num) // 3*7
                  let ge_min = v.min_num*Math.floor(val/v.min_num) //  3*6
                  v.num = ge_max < limit ? ge_max : ge_min 
                  if(val % v.min_num !== 0) {
                    ElMessage.warning(`必须以${v.min_num}的倍数修改，已为您修正。`)
                  }
              }
            }else {
              if(val > max_pan) {
                v.num = max_pan
                ElMessage.warning(`最大订购量为${v.num}${v.pack_type}，已为您修正。`)
              }else if(val < 1) {
                v.num = 1
                ElMessage.warning(`最小起订量为${v.num}${v.pack_type}，已为您修正。`)
              }else {
                v.num = val
              }
            }
            change_num(v);
        };

        // 购买数量改变
        let change_num = (v) => {
            let url = `api/shopping_car/set_num`;
            let num = v.type == '个'? v.num : v.num * v.pack
            postData({
                url: url,
                data: {
                    goods: v.id,
                    num: num,
                },
            }).then((res) => {
                let data = res.data
                if( data.code == 200) {
                    v.total = data.data.money
                }else {
                    v.total = 0
                }
            });
        };

        // 加入购物车
        let join = (v) => {
            let num = v.type == '个'? v.num : v.num * v.pack
            if(state.userid) {
                postData({
                url: `/api/shopping_car/store`,
                data: {
                    goods_id: v.id,
                    num: num
                },
                    }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        ElMessage.success(data.data.msg);
                        get_list()
                        store.commit('setCount');
                    }else if (data.code === 422) {
                        ElMessage.error(data.data.msg);
                    }else {
                        ElMessage.error(data.data.msg);
                    }
                });
            }else {
                store.commit('setLoginBox', true)
            }
        }

        let change_unit = (e, v) => {
          v.type = e
          let limit = v.purchase_limit && v.purchase_limit > 0 ? (v.stock > v.purchase_limit ? v.purchase_limit : v.stock) : v.stock
          let max = v.min_num*Math.floor(limit/v.min_num)
          let max_pan = Math.floor(limit/v.pack)
          if(e =='个') {
            let ge = v.num * v.pack
            v.num = ge > max ? max: ge
          }else{
            let pan =  Math.ceil(v.num/v.pack)
            v.num = pan > max_pan ? max_pan: pan
          }
          change_num(v)
        }

        let change_img = (item,v) => {
            item.img_big = v
        }

        let open = (e,v) => {
          if(state.product_list.length > 0) {
              state.product_list.forEach((a) => {
                  a.show = false
              })
          }
          v.show_big = true
        }

        let to_detail = (v) => {
            router.push({
                path: '/detail',
                query: {
                    id: v.id
                }
            })
            window.scrollTo(100,0)
        }

        let handleCurrentChange = (val) => {
            state.page = val
            get_list(true)
        }

        onMounted(()=>{
          nextTick(()=>{
              document.addEventListener("mouseover", function (e) {
                let str = e.target.className
                let arr = []
                if(str) {
                  if(str.indexOf(' ') > 0) {
                    arr = str.split(' ')
                  }else {
                    arr = [str]
                  }
                }
                let name = ['list_con','list_img','img','list_box','list_view','big','sample','detail_banner','show-item','show-img','carousel_box','banner_img','arrow','show-carousel','el-carousel__arrow','el-carousel','el-carousel__container','el-carousel__arrow','el-carousel__item']
                let bol = false
                name.forEach((v)=>{
                    if(arr.includes(v)){
                      bol = true
                    }
                })
                  if (!bol) {
                      if(state.product_list.length > 0) {
                          state.product_list.forEach((v) => {
                              v.show_big = false
                          })
                      }
                  }
              });
          })

        })

        return {
            ...toRefs(state),
            add,
            sub,
            num_blur,
            join,
            get_list,
            to_detail,
            handleCurrentChange,
            change_more,
            add_cond,
            clear_cond,
            remove_filter,
            use_filter,
            change_filter,     
            get_menu,
            change_unit,
            change_img,
            open,

        };
    },
    components: {
        topnav,
        topmenu,
        topbread,
        foot,
        toolbar
    },
};
</script>
<style lang="less" scoped>
.pagination {
    margin: 40px auto 0;
    text-align: center;
    }

    /deep/ .el-pagination {
    padding: 0;
    font-weight: 400;

    .el-pager {
        .number {
        height: 30px;
        line-height: 30px;
        color: #1C46B7;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #1C46B7;
        border-radius: 2px;
        }
        .number:hover {
        color: #1C46B7 !important;
        }

        .active:hover {
        color: #fff !important;
        }
    }

    .active {
        background-color: #1C46B7 !important;
    }

    button {
        height: 30px;
        line-height: 30px;
        background-color: #fff !important;
        border: 1px solid #666;

        span {
        padding: 0 12px;
        font-size: 16px;
        color: #666;
        }
    }

    .el-pagination__jump {
        margin-left: 0;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        color: #666;

        .el-input__inner {
        color: #666;
        border: 1px solid #666;
        border-radius: 2px;
        }
    }
}
.product {
    padding: 154px 0 440px;
    background-color: #fff;
    .product_wrap {
        padding: 20px 0 40px;
    }

    .product_filter {
        width: 100%;
        background: #FCFCFC;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        padding: 20px;
        margin-bottom: 20px;

        .filter_more {
            width: 24px;
            height: 24px;
            background: #FFFFFF;
            border: 1px solid #E1E1E1;
            margin-right: 28px;
            cursor: pointer;
        }

        .filter_more.show {
            .t-icon {
                transform:rotate(180deg);
            }
        }

        .filter_input {
            border-bottom: 1px solid #E1E1E1;
            height: 32px;

        }

        .filter_ipt {
            flex: 1;
        }

        /deep/.el-input__inner,.el-input{
            flex: 1;
            height: 30px!important;
            line-height: 30px!important;
            border: none!important;
        }

        .btn {
            height: 30px;
            width: 30px;
            background: #fff;
            cursor: pointer;
            .t-icon {
                height: 22px;
                width: 22px;
            }       
        }

        .filter_wrap {
            width: 100%;
            flex: 1;
            
        }

        .filter_items {
            width: 96%;
            overflow-x:auto;
            margin-bottom: 22px;
        }

        .filter_list {
            height: 100%;
            white-space: nowrap;
            padding-bottom: 10px;
        }

        .filter_title {
            margin-bottom: 6px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #333333;
        }
        .filter_item {
            display: inline-block;
            margin-right: 20px;
            width: 200px;
        }
        .filter_box {
            border: 1px solid #E1E1E1;
        }
        
        .show_list {
            flex: 1;
            height: 120px;
            overflow-y: auto;
            padding: 3px 10px;
            background: #FFFFFF;
            font-size: 13px;
            line-height: 28px;
            color: #333333;

            li {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
            }

            li.grey {
                color: #b1b1b1;
                cursor:default;
            }

            li.active {
                color: #fff;
                background: #559BFF;

            }
        }
        .show_list.show {
            height:318px;
        }

        .cond_list {
            .cond_item {
                position: relative;
                display:inline-block;
                background: #FFFFFF;
                border: 1px solid #DBDBDB;
                border-radius: 2px;
                padding: 3px 18px;
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
                color: #1C46B7;
                margin: 0 18px 18px 0;
                box-sizing: border-box;

                .icon {
                    cursor:pointer;
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    width: 16px;
                    height: 16px;
                    background: #1C46B7;
                    border-radius: 50%;
                    line-height: 16px;
                    text-align:center;
                    .iconfont {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }

        .result_remove,.result_use {
            width: 87px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #013EAA;
            border-radius: 2px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 600;
            line-height: 28px;
            color: #1C46B7;
            text-align: center;
            margin-right: 20px;
            cursor: pointer;
        }

        .result_use {
            background: #1C46B7;
            color: #fff;
        }

        .result_num {
            font-size: 13px;
            font-weight: 600;
            line-height: 30px;
            color: #666;
            span {
                color: #FF7F23;
            }
        }
    }
    
    .product_total {
        margin-bottom: 20px;
        .product_title {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #333;
        }
        .product_num {
            font-size: 14px;
            color: #333;
            span {
                color: #E6A91F;
                margin: 0 5px;
            }
        }

    }
    

    .productMain {
        position: relative;
        width: 100%;
        margin: 0 auto;

        .product_table {
            margin-bottom: 20px;
            border: 1px solid #eaeaea;

            .productMain_hd {
                width: 100%;
                height: 32px;
                line-height: 32px;
                background: #f5f5f5;
                li {
                    font-size: 12px;
                    color: #666666!important;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                }

                .order_lists {
                    padding: 0;
                }

                .productTop {
                    height: 32px;
                }
            }

            .order_lists {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 20px 0 15px 0;
                border-bottom: 1px solid #eaeaea;
                align-items: flex-start;

                li {
                    padding: 0 10px;
                    font-size: 12px;
                    color: #333;
                    line-height: 18px;
                    
                }

                /* 图片 */
                .list_con {
                    cursor:pointer;
                    width: 160px;

                    .img {
                        display: inline-block;
                        width: 128px;
                        height: 128px;
                        border: 1px solid #eaeaea;
                        object-fit: contain;
                        flex-shrink: 0;
                    }
                }

                .list_img {
                  position: relative;
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                }

                .list_box {
                  position:absolute;
                  top: 0;
                  left: 150px;
                  z-index:99;
                }
                .list_view {
                    position: relative;
                    margin-right: 20px;
                    background: #Fff;
                    padding: 14px 15px;
                    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
                    .font {
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      color: #999999;
                      margin: 12px 0 5px;
                      text-align:center;
                    }
                
                    .big {
                        height: 328px;
                        width: 328px;
                        margin-bottom: 15px;
                        background: #fafafa;
                        object-fit: contain;
                    }
                    .arrow {
                      position: absolute;
                      top: 22px;
                      left: -19px;
                    }
                    .sample {
                        position: absolute;
                        top: 14px;
                        left: 15px;
                        height: 90px;
                        width: 90px;
                        z-index: 991;
                    }
                    .detail_banner {
                        .action {
                            height: 90px;
                            width:30px;
                            background: rgba(112, 112, 112, 0.39);
                            cursor: pointer;
                            .iconfont {
                                color: #fff;
                            }
                        }
                    }
                    
                    /deep/ .el-carousel__item {
                        overflow: hidden;
                        height: 90px;
                    }

                    /deep/ .el-carousel__indicator--horizontal {
                      display: none;
                    }

                    /deep/ .el-carousel__arrow--left {
                      left: -30px;
                      height: 90px;
                      width:30px;
                      background: rgba(112, 112, 112, 0.39);
                      cursor: pointer;
                      border-radius: 0;
                    }

                    /deep/ .el-carousel__arrow--right {
                      right: -30px;
                      height: 90px;
                      width:30px;
                      background: rgba(112, 112, 112, 0.39);
                      cursor: pointer;
                      border-radius: 0;
                    }

                    .show-img {
                        flex: 1;
                        padding: 0 30px;
                        height: 90px;
                        width: 100%;
                        
                    }
                    .show-item {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        .carousel_box {
                          height: 100%; 
                          width: 100%;
                          padding: 0 22px;
                        }

                        .banner_img {
                            height: 90px;
                            width: 90px;
                            background: #fafafa;
                            object-fit: contain;
                            cursor: pointer;
                            opacity: 0.6;
                        }

                        .banner_img.active {
                            opacity: 1;
                            border: 1px solid #013EAA;
                        }
                    }
                }

                .list_info {
                  width:300px;
                  justify-content: flex-start;
                }

                /* 产品名称*/
                .list_name {
                  cursor:pointer;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 20px;
                  color: #1C46B7;
                  margin-bottom: 5px;
                  .name {
                    margin-right: 5px;
                  }
                  .type {
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 12px;
                    color: #FFFFFF;
                    padding: 0 4px;
                    background: #559BFF;
                    border-radius: 2px;
                    white-space:nowrap;
                  }
                }

                .list_name:hover {
                  color:#3385F6;
                }

                .list_item {
                  margin-bottom: 5px;
                  .title {
                    display: inline-block;
                    width: 60px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #666666;
                  }
                  .val {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #333333;
                  }
                  
                }

                .list_book {
                  display:flex;
                  align-items: flex-start;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  color: #1C46B7;
                  text-decoration: underline;
                  .t-icon {
                    margin-right: 6px;
                  }
                }


                /* 参数描述 */
                .list_desc {
                    width: 300px;
                    justify-content: flex-start; 
                    cursor: pointer;
                    
                }

                /* 单价 */
                .list_price {
                    width: 200px;
                    justify-content: flex-start;
                    .price_box {
                        font-size: 12px;
                        line-height: 20px;
                        color: #333333;
                    }
                }

                /* 库存 */
                .list_store {
                    width: 150px;
                    justify-content: flex-start;
                }

                /* 购买数量 */
                .list_amount {
                    width: 250px;

                    /deep/.el-select .el-input {
                      width: 65px;
                    }

                    .amount_wrap {
                        padding: 0; 
                        margin-right: 20px;
                    }

                    .amount_box {
                        height: 30px;
                        position: relative;
                        margin-bottom: 5px;

                        /deep/ .el-input {
                        width: 46px;
                        height: 30px;
                        line-height: 30px;
                        color: #333;
                        text-align: center;
                        background-color: #fff;
                        z-index: 2;
                        }

                        /deep/ .el-input__inner {
                        padding: 0 3px;
                        width: 46px;
                        height: 30px;
                        line-height: 30px;
                        border: 1px solid #aaa;
                        color: #343434;
                        text-align: center;
                        background-color: #fff;
                        z-index: 2;
                        border-radius: 0;
                        }

                        /deep/ .el-button {
                        padding: 0;
                        width: 30px;
                        height: 30px;
                        min-height: 30px;
                        border: 1px solid #aaa;
                        background: #f0f0f0;
                        text-align: center;
                        color: #444;
                        border-radius: 0;
                        }

                        .reSty {
                        color: #cbcbcb;
                        pointer-events: none;
                        }

                        .reSty:hover {
                        color: #cbcbcb;
                        }

                        .reduce {
                        border-right-color: transparent;
                        }

                        .plus {
                        border-left-color: transparent;
                        }
                    }

                    .inventory_count {
                        display: flex;
                        align-items: center;
                        .sum_price {
                            color: #DE6F00;
                            font-weight: bold;
                            line-height: 1;
                        }
                    }
                    .join {
                        min-height: 26px;
                        padding: 7px 8px;
                        background: #1C46B7;
                        border-radius: 2px;
                        cursor: pointer;
                        color:#fff;
                        font-size: 12px;
                        margin-right: 5px;
                        .iconfont {
                            color: #fff;
                            margin-right: 5px;
                            vertical-align: text-top;
                        }
                    }
                    .join.is-disabled {
                        background: #E2E2E2;
                        cursor: not-allowed;
                    }

                    .op_wrap {
                      margin-top: 5px;
                      align-items: center;
                    }

                    .inventory_count {
                        height: 19px;
                        display: flex;
                        align-items: center;
                        .sum_price {
                            color: #DE6F00;
                            font-weight: bold;
                            line-height: 1;
                            margin-left: 2px;
                        }
                    }

                    .blue_count {
                        height: 19px;
                        display: flex;
                        align-items: center;
                        color:#1C46B7;
                        .blue_price {
                            color: #1C46B7;
                            font-weight: bold;
                            line-height: 1;
                            margin-left: 2px;
                        }
                    }
                }

            }

            .order_lists:last-child {
                border-bottom: none;
            }

            
        }
        .product_page {
            padding: 40px 0 0;
        }
    }

    .bgc_empty {
        background: #F5F5F5;
        padding: 100px 0;
        img {
            width: 128px;
            height: 142px;
            margin-right: 40px;
        }
        .bgc_txt {
            color: #333;
            font-size: 13px;
            line-height: 18px;

            .none {
                font-weight: 600;
                font-size: 16px;
                margin: 14px 0 20px;
            }
            .can {
                margin-bottom: 20px;
            }
        }
    }
}
</style>
<style>
.el-popper.is-light.pb_pop {
  font-size: 12px !important;
}
</style>
